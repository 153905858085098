import React from 'react';
import PropTypes from 'prop-types';
import { withFederation } from '@unisporkal/federation';
import { Providers } from './providers';
import { GettySearchBar } from './components/getty';
import { IstockSearchBar } from './components/istock';
import configWithDefaults from './utils/configWithDefaults';
import { APP_NAME, COMPONENTS } from './constants';

const SearchBar = ({ siteName, user, config, locale }) => {
  const Component = siteName === 'getty' ? GettySearchBar : IstockSearchBar;
  const searchBarConfig = configWithDefaults(siteName, config);

  const searchBar = () => (
    <Providers
      config={searchBarConfig}
      locale={locale}
      siteName={siteName}
      user={user}
    >
      <Component />
    </Providers>
  );

  return searchBar();
};

/* eslint react/forbid-prop-types: "off" */
SearchBar.propTypes = {
  siteName: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};

export default withFederation(APP_NAME, COMPONENTS.SEARCH_BAR, SearchBar);
